import dynamic from "next/dynamic";
import { CardGridWrapper, BigCardWrapper, BigCard, AnchorWrapper } from "./styles";
import { Section } from "../index";
import {
  LocationFilled,
} from '@carbon/icons-react';
const CardItem = dynamic(() => import("../../elements/card-item/card-item"));
const Link = dynamic(() => import("../../elements/link/link"));

export const CardGrid = ({
  id,
  title,
  sectionSubtitle,
  hideLineSeparation,
  cardItems,
  bigCardLink,
  displaySetting,
  showAnchors
}) => {
  if (bigCardLink) cardItems = cardItems.slice(0, 2);

  return (
    <Section
      key={id}
      title={title}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      {showAnchors &&
        <AnchorWrapper>
          {cardItems.map((card) => (
            <a key={card.id} href={`#${card.id}`}>
              <LocationFilled size={32} /> {card.title}
            </a>
          ))}
        </AnchorWrapper>
      }
      <CardGridWrapper>
        {cardItems.map((card) => (
          <CardItem key={card.id} {...card} />
        ))}
        {bigCardLink && (
          <BigCardWrapper>
            <Link {...bigCardLink}>
              <BigCard>{bigCardLink.text}</BigCard>
            </Link>
          </BigCardWrapper>
        )}
      </CardGridWrapper>
    </Section>
  );
};

export default CardGrid;
